<template>

  <div class="library__view">
    <h1 class="library__title">Icons</h1>

    <section id="buttons" class="library__section">
      <p class="library__section-title d-flex justify-content-between">
        Design icons

        <vb-checkbox
            class="text--base"
            @change="toggle_dotted"
            :vb_options="{text: 'Mostra versione dotted', switch: true, type: 'light'}"
        />
      </p>


      <b-row>
        <b-col md="1" v-for="(icon,icon_index) in Object.keys(DesignIcons).sort()" v-bind:key="icon_index">
          <div class="mb-4 pt-4 d-flex align-items-center flex-column justify-content-center">
            <small class="mb-2">
              {{ icon }}
            </small>
            <vb-icon
                :name="icon"
                :color="show_dotted ? '000' : '999'"
                :dotted="show_dotted"
            />
          </div>
        </b-col>
      </b-row>
    </section>

    <section id="buttons" class="library__section">
      <p class="library__section-title">Custom icons</p>

      <b-row>
        <b-col md="1" v-for="(icon,icon_index) in Object.keys(CustomIcons).sort()" v-bind:key="icon_index">
          <div class="mb-4 pt-4 d-flex align-items-center flex-column justify-content-center">
            <small class="mb-2">
              {{ icon }}
            </small>
            <vb-icon
                :name="icon"
            />
          </div>
        </b-col>
      </b-row>
    </section>

  </div>

</template>

<script>
import * as DesignIcons from '@/components/svg/design-icons'
import * as CustomIcons from '@/components/svg/custom-icons'

export default {
  name: 'icons',

  components: {
  },

  props: {},

  data() {
    return {
      show_dotted: false
    }
  },

  computed: {
  },

  created() {
    this.DesignIcons = DesignIcons
    this.CustomIcons = CustomIcons
  },

  mounted() {
  },

  methods: {
    toggle_dotted() {
      this.show_dotted = !this.show_dotted;
    }
  },
}
</script>

<style lang="scss">

</style>